@font-face {
  font-family: "poppins-regular";
  src: url("./assets/font/Poppins-Regular.ttf");
}

@font-face {
  font-family: "poppins-bold";
  src: url("./assets/font/Poppins-SemiBold.ttf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --txtcolor: red;
}
.test {
  & a {
    color: var(--txtcolor);
  }
}

* {
  font-family: poppins-regular;
}

.page-title html,
body {
  /* @apply bg-floor; */
  overflow-y: overlay;
}
