.top-bg {
  background: linear-gradient(
    90deg,
    #000000 0%,
    #000000 0.01%,
    rgba(0, 0, 0, 0) 100%
  );
}

.top-card:hover {
  box-shadow: -13px -11px 69px rgba(34, 100, 237, 0.1),
    18px 20px 69px rgba(100, 210, 208, 0.15);
  cursor: pointer;
}
