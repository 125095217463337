.d-skeleton {
  width: inherit;

  .d-skeleton__node {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .d-skeleton__item {
    height: inherit;
    min-height: 1.5rem;
    border-radius: 6px;
    background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 75%, 0.1) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 75%, 0.1) 63%
    );
    border-radius: 4px;
    list-style: none;
    background-size: 400% 100%;
    background-position: 100% 50%;
    animation: skeleton-loading 1.4s ease infinite;
  }

  .d-skeleton__card {
    width: 100%;
    height: 100%;
    min-width: 2rem;
    min-height: 2rem;
  }

  .d-skeleton__full {
    width: 100%;
    height: 100%;
  }

  .d-skeleton__round {
    border-radius: 50%;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
