.featured-project-header {
  background: linear-gradient(
    92deg,
    theme(colors.primary1) 22%,
    theme(colors.primary2) 142%
  );
}

.d-markdown {
  font-weight: 400;
  font-size: 1rem;
  color: theme("colors.black3");

  * {
    line-height: 2;
  }

  h2,
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: theme("colors.black-80");
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1rem;
  }

  ul {
    list-style: inside;
  }

  hr {
    margin: 2.5rem 0;
  }
}

.md--d-markdown {
  ul {
    li {
      list-style-position: outside;
      margin-left: 1.2rem;
    }
  }
}
