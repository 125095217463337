.wish-list {
  .wish-item {
    cursor: pointer;

    .wish-item__label {
      font-size: 1.3rem;
      font-weight: 600;

      &:hover {
        box-shadow: -13px -3px 69px rgba(34, 100, 237, 0.06),
          5px 3px 69px rgba(100, 210, 208, 0.1);
        border-radius: 12px;
      }
    }
    .wish-item__body {
      border: 2px solid rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      border-radius: 12px;
      margin: 0.75rem 0;
    }
  }
}
