@tailwind components;

@layer components {
  .d-button {
    width: 9.125rem;
    height: 2.875rem;

    .d-button__inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      background: linear-gradient(
        45deg,
        theme('colors.primary2'),
        theme('colors.primary1')
      );
      border-radius: 40px;
      color: theme('colors.white');

      @apply cursor-pointer;

      &:hover {
        @apply opacity-95;
      }
    }

    .d-button__plain {
      border: 1px solid theme('colors.black2');
      background: transparent;
      color: theme('colors.black-80');

      &:hover {
        background: linear-gradient(
          45deg,
          theme('colors.primary2'),
          theme('colors.primary1')
        );
        border: none;
        color: theme('colors.white');
      }
    }

    .d-button__light {
      background: transparent;
      border: 1px solid #fff;
      color: theme('colors.white');
    }
  }
}
