.documentation {
  .card {
    box-shadow: -13px -11px 69px rgba(34, 100, 237, 0.06),
      18px 20px 69px rgba(100, 210, 208, 0.1);
    border-radius: 6px;
  }
  .top-line {
    position: absolute;
    top: -0.1rem;
    width: 1rem;
    left: 50%;
    transform: translateY(-50%);
    box-shadow: -13px -11px 69px rgb(34 100 237 / 6%),
      18px 20px 69px rgb(100 210 208 / 10%);
  }
}
