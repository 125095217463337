.solvheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.75rem;
  z-index: 9999;

  .nav-menu__item {
    margin-left: 5rem !important;
    margin-right: 0 !important;
  }
}
.md-solvheader {
  height: 3.75rem;
}
