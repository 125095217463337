.swiper {
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.swiper-slide {
  height: auto;
}
.swiper-pagination-bullet {
  display: inline-block !important;
  width: 0.875rem;
  height: 0.875rem;
  border: 0.09rem solid #959bb4;
  box-sizing: border-box;
  background: transparent;
  margin-right: 0.6563rem !important;
}
.swiper-pagination-bullet-active {
  border: none;
  background: linear-gradient(
    91.22deg,
    #2264ed -21.66%,
    #64d2d0 142.44%
  ) !important;
}
.home-wrapper {
  position: relative;
  height: 100%;

  .title {
    height: 5rem;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0;
    text-align: center;
    line-height: 6.25rem;
    font-weight: 600;
  }
  .subtitle {
    width: 50rem;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0;
    text-align: center;
    line-height: 1.75rem;
    font-weight: 400;
    margin-top: 2rem;
    height: 6.0625rem;
  }
  .second-screen {
    height: 34.5625rem;
    overflow: hidden;
    background: linear-gradient(309.33deg, #64d2d0 16.66%, #2264ed 93.4%);
  }
  .second-title {
    font-weight: 600;
    font-size: 3.75rem;
    line-height: 125%;
    color: rgba(0, 0, 0, 0.8);
    width: 33.8125rem;
    height: 8.375rem;
  }
  .second-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.6);
    width: 29.375rem;
    height: 7.25rem;
  }
  .three-title {
    width: 53.875rem;
    font-size: 3.75rem;
    color: rgba(0, 0, 0, 0.85);
    letter-spacing: 0;
    line-height: 4.6875rem;
    font-weight: 600;
  }
  .sea {
    height: 45%;
    background: url(../../assets/images/home/sea.png) no-repeat center center;
    background-size: cover;
  }
  .eip {
    width: 17rem;
    height: 3.5625rem;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0;
    line-height: 1.5625rem;
    font-weight: 400;
    margin-top: 1.375rem;
    margin-bottom: 1.5rem;
  }
  .eip-sub {
    width: 16.625rem;
    height: 6.5625rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0;
    line-height: 1.675rem;
    font-weight: 400;
  }
  .item {
    width: 22.5rem;
    height: 26.25rem;
    background-color: white !important;
    border-radius: 16px;
    padding: 2.5rem 2.75rem 0rem;
    margin: auto;
  }
  .bg {
    position: absolute;
    width: 50.8431rem;
    height: 46.56rem;
    left: 0;
    top: 0;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(136, 241, 239, 0.3) 0%,
      rgba(241, 241, 242, 0) 100%
    );
    transform: rotate(-29.95deg);
  }

  .slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes slideInUp {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes slideInUp {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .item:hover {
    background: linear-gradient(180deg, #2264ed 0%, #64d2d0 100%);
    /* 蓝绿阴影 */
    box-shadow: -13px -11px 69px rgba(34, 100, 237, 0.06),
      18px 20px 69px rgba(100, 210, 208, 0.1);
    span {
      color: white !important;
    }
  }

  .txt1 {
    font-weight: 600;
    font-size: 3.75rem;
    line-height: 125%;
    color: rgba(0, 0, 0, 0.8);
  }
  .txt2 {
    --rainbow-gradient: linear-gradient(to left, #03d5e4 20%, #1863ff 120.08%);
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
    font-weight: 600;
    font-size: 2rem;
    line-height: 125%;
    background-image: var(--rainbow-gradient, #1863ff);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    text-shadow: none !important;
  }
  .txt-1 {
    --rainbow-gradient: linear-gradient(to left, #03d5e4 20%, #1863ff 120.08%);
    background-image: var(--rainbow-gradient, #1863ff);
  }
  .txt-2 {
    --rainbow-gradient: linear-gradient(to left, #03d5e4 50%, #1863ff 120%);
    background-image: var(--rainbow-gradient, #1863ff);
  }
  .txt-3 {
    --rainbow-gradient: linear-gradient(to left, #03d5e4 50%, #1863ff 110.08%);
    background-image: var(--rainbow-gradient, #1863ff);
  }

  .txt3 {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.6);
    width: 26.75rem;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
  }
  .btn {
    width: 4rem;
    height: 4rem;
    right: 0;
    top: 22%;
    background: linear-gradient(120.88deg, #2264ed -22.98%, #64d2d0 119.07%);
    box-shadow: -4px -3px 19px rgba(100, 210, 208, 0.05),
      10px 6px 26px rgba(34, 100, 237, 0.18);
    border-radius: 12px;
  }
}

.screen-one {
  height: 100%;
  background: url(../../assets/images/home/screen1-bg.png) no-repeat center
    center;
  background-size: cover;

  .title {
    font-size: 2.625rem;
    line-height: 1.2;
    height: auto;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1.6;
    width: auto;
  }
  .sea {
    display: none;
  }
}

.second-container {
  .second-container-inner {
    display: block;

    .second-container-module {
      display: block;

      .second-title {
        margin-top: 2rem;
        font-size: 2.25rem;
        width: auto;
        line-height: 1.4;
        height: auto;
      }
      .second-subtitle {
        font-size: 1rem;
        width: auto;
        height: auto;
        line-height: 1.4;
      }
      .three-title {
        font-size: 2.25rem;
        width: auto;
        height: auto;
        line-height: 1.4;
      }
    }

    .market-img {
      width: 120%;
    }
    .item-modules {
      flex-wrap: wrap;

      .item-module {
        width: 100%;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }

        .item {
          width: 100%;
          height: auto;
          padding: 2.5rem 2.75rem;

          &:hover {
            background: white !important;
            /* 蓝绿阴影 */
            box-shadow: -13px -11px 69px rgba(34, 100, 237, 0.06),
              18px 20px 69px rgba(100, 210, 208, 0.1);

            span {
              color: unset !important;
            }
          }
        }
      }
    }
  }
}
